<template>
  <div class="steps_cont">
    
    <div class="steps_left">
      
      <div class="steps_title">
        <span @click="$router.go(-1)" class="close"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg></span>
        <span v-if="edit === false">Ajouter un produit</span>
        <span v-else>Editer un produit</span>
      </div>
    
      <div class="steps_format" v-if="boutique.boutique">
        
        <div class="step">
          
          <div class="title">
            <span class="nb">1</span>
            <span class="txt">Informations</span>
          </div>
        
          <div class="content">
            <label for="">Nom de l'article</label>
            <el-input class="input_1" placeholder="Mon article" v-model="name"></el-input>
            
            <label for="">Description de l'article</label>
            <el-input @change="$forceUpdate" type="textarea" placeholder="Description de mon article" maxlength="1500" show-word-limit v-model="description" resize="vertical" :autosize="{ minRows: 5 }"></el-input>
            
            <button @click="next_step" v-if="step === 1" :disabled="name && description ? false : true" class="simple">Continuer</button>
            
          </div>
          
            
        </div>
        <div class="step" :class="{ close: step < 2 }">
          <div class="anchor"></div>
          <div class="title">
            <span class="nb">2</span>
            <span class="txt">Tarification</span>
          </div>
          <div class="content">
            <div class="i">
              <label for="">Prix de vente TTC</label>
              
              <el-input class="input_small input_1" placeholder="0.00€" v-model="price" type="number" step="any" name="price"></el-input>
              <label for="">Réduction en %</label>
              <el-input class="input_small" placeholder="0%" v-model="reduc" type="number" step="any" name="reduc"></el-input>

              <button @click="next_step" v-if="step === 2" :disabled="price && price > 0 ? false : true" class="simple">Continuer</button>
            </div>
          </div>
        </div>
        <div class="step" :class="{ close: step < 3 }">
          
          <div class="title">
            <span class="nb">3</span>
            <span class="txt">En détail</span>
          </div>
          <div class="content">
            <div class="i">
              <label for="">Catégorie</label>
              <el-select v-model="categorie" placeholder="Catégorie de l'article">
                <el-option
                    v-for="item in boutique.boutique.categories"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
      
              <label for="">Options</label>
                <el-select v-model="options" multiple placeholder="Options actives">
                  <el-option
                      v-for="item in options_all"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              
              <button @click="next_step" v-if="step === 3" :disabled="categorie ? false : true" class="simple">Continuer</button>
            </div>
            
          </div>
        </div>
        <div class="step step4" :class="{ close: step < 4 }">
          
          <div class="title">
            <span class="nb">4</span>
            <span class="txt">Photo</span>
          </div>
          <div class="content">
            <div class="i">
              <div class="upload_block">
                <input @change="upload_img" type="file" id="file" accept="image/*">
<!--                <img v-if="imageUrl" :src="imageUrl" alt="upload">-->
                <img v-if="img_key" :src="$img_path + img_key" alt="upload">
                <i v-else class="ico el-icon-plus upload_block-uploader-icon"></i>
              </div>

<!--              <el-upload-->
<!--                  class="upload_block"-->
<!--                  ref="upload"-->
<!--                  :multiple="false"-->
<!--                  :action="$api_ip + 'bo/produit/add/image'"-->
<!--                  :show-file-list="false"-->
<!--                  :on-error="error"-->
<!--                  :auto-upload="false"-->
<!--                  :before-upload="beforeAvatarUpload"-->
<!--                  :on-success="handleAvatarSuccess"-->
<!--              >-->
<!--                <el-image class="img" v-if="imageUrl" :src="imageUrl" fit="cover"></el-image>-->
<!--                <el-image class="img" v-if="img" :src="$static_patch + img" fit="cover"></el-image>-->
<!--                <i v-else class="el-icon-plus upload_block-uploader-icon"></i>-->
<!--              </el-upload>-->

              <div class="info mobile_only">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="48" height="48" viewBox="0 0 24 24"><path d="M9,1H3A2,2 0 0,0 1,3V16A2,2 0 0,0 3,18H9A2,2 0 0,0 11,16V3A2,2 0 0,0 9,1M9,15H3V3H9V15M21,13H13V15H21V21H9V20H6V21A2,2 0 0,0 8,23H21A2,2 0 0,0 23,21V15A2,2 0 0,0 21,13M23,10L19,8L20.91,7.09C19.74,4.31 17,2.5 14,2.5V1A9,9 0 0,1 23,10Z" /></svg>
                <span>Pour garder les proportions, il est conseillé de prendre la photo en paysage.</span>
              </div>
              
              <button @click="next_step" v-if="step === 4" :disabled="name && description && price && price > 0 && categorie ? false : true" class="simple">Valider</button>
            </div>
            
          </div>
        </div>
        
      </div>
    </div>
    <div class="steps_right">
      <img src="../../assets/img/illu/user_interface_.svg" alt="">
    </div>
  </div>
</template>

<script>
import anime from 'animejs'
export default {
  name: "test",
  data() {
    return {
      name: null,
      description: null,
      
      price: null,
      reduc: null,

      categorie: null,
      options: null,      
      
      boutique: {},
      options_all: [],

      img_key: null,
      imageUrl: null,
      
      edit: false,
      produit: {},
      
      step: 1,
    }
  },
  methods: {
    next_step() {
      let self = this
      if(this.step < 4) {
        this.step++
        this.$nextTick(() => {
          this.scrollToElement()
        })
        setTimeout(function() {
          document.querySelector('.step:nth-child(' + self.step + ') .input_1 input').focus()
        }, 200)
      }
      else
        this.save()
    },
    save() {
      if(this.$route.params.product_id) {
        this.$http.put('/bo/produit', {
          boutique_id: this.$store.state.user.user.boutique,
          product_id: this.$route.params.product_id,
          name: this.name,
          description: this.description,
          price: this.price,
          reduc: this.reduc,
          categorie: this.categorie,
          active: this.active,
          stock: this.stock,
          stock_suivi: this.stock_suivi,
          options: this.options,
          img: this.img_key
        }).then((r) => {
          console.log(r)
          if(r.status === 201) {
            this.$notify({
              title: 'Article enregistré',
              offset: this.$notifyOffest,
              type: 'success'
            });
            this.$store.dispatch("boutique_bo/sync");
            this.$router.go(-1)
          }
        })
      }
      else {
        this.$http.post('/bo/produit', {
          boutique_id: this.$store.state.user.user.boutique,
          name: this.name,
          description: this.description,
          price: this.price,
          reduc: this.reduc,
          categorie: this.categorie,
          stock: this.stock,
          active: this.active,
          stock_suivi: this.stock_suivi,
          options: this.options,
          img: this.img_key
        }).then((r) => {
          console.log(r)
          if(r.status === 201) {
            this.$notify({
              title: 'Article ajouté',
              offset: this.$notifyOffest,
              type: 'success'
            });
            this.$store.dispatch("boutique_bo/sync");
            this.$router.go(-1)
          }
        })
      }
    },
    scrollToElement() {
      // step = parseInt(step)
      // console.log(step)
      // const el = this.$el.querySelector('.step:nth-child('+step+') .anchor');
      const y = document.querySelector('.steps_cont').scrollHeight;
      console.log('y', y)
      //
      // if (el) {
      //   el.scrollIntoView({  behavior: 'smooth', block: 'start' });
      // }
      anime({
        targets: '.steps_cont',
        scrollTop: y,
        duration: 1000,
        easing: 'easeInOutQuad'
      });
    },
    // handleAvatarSuccess(res, file) {
    //   this.imageUrl = URL.createObjectURL(file.raw);
    //   this.img = res
    // },
    // beforeAvatarUpload(file) {
    //   const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
    //   const isLt2M = file.size / 1024 / 1024 < 200;
    //
    //   if (!isJPG) {
    //     this.$message.error('Avatar picture must be JPG format!');
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('Avatar picture size can not exceed 2MB!');
    //   }
    //   return isJPG && isLt2M;
    // }
    upload_img(e) {
      this.$upload_img(e, 500, 'produit').then((d) => {
        console.log(d)
        this.imageUrl = d.blob
        this.img_key = d.key
      })
    }
  },
  created() {
    if(this.$route.params.product_id) {
      this.edit = true
      this.step = 4
      this.$http.get('/produit/' + this.$route.params.product_id).then((response) => {
        this.produit = response.data
        for(let n in this.produit) {
          this[n] = this.produit[n]
        }
      })
    }
    
    this.$http.get('/boutique/' + this.$store.state.user.user.boutique).then((response) => {
      this.boutique = response.data
    })
    if(this.$route.params.categorie)
      this.categorie = this.$route.params.categorie

    this.$http.get('/bo/boutique/options').then((response) => {
      this.options_all = response.data
    })
  },
  mounted() {
    // if(this.edit === false) {
    //   setTimeout(function() {
    //     document.querySelector('.step:nth-child(1) .input_1 input').focus()
    //   }, 200)
    // }
  }
}
</script>

<style>
  .steps_format input, 
  .steps_format .el-textarea,
  .steps_format .el-select
  { max-width: 440px!important; }
  
  .steps_format .input_small { max-width: 290px }
  
   /*{ max-width: 490px!important; width: 490px!important; }*/
  
  .steps_format .el-textarea .el-input__count { height: 16px; color: var(--black-sub) }
</style>
<style scoped>

  .upload_block { position: relative; border: 1px dashed #DCDFE6; border-radius: 4px; background: #f9f9f9; cursor: pointer; overflow: hidden }
  .upload_block:hover { border-color: #C0C4CC }
  .upload_block input { position: absolute; top: 0; right: 0; bottom: 0; left: 0; opacity: 0; cursor: pointer; z-index: 2 }
  .upload_block img { position: absolute; height: 100%; top: 50%; left: 50%; transform: translate(-50%, -50%) }
  .upload_block i { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%) }
  
  .info { text-transform: none; color: var(--black-main); font-weight: 400; font-size: 14px; margin-bottom: 0px; display: flex; margin: 16px 0; }
  .info svg { margin-right: 10px; height: 40px; fill: var(--black-main) }
  label { text-transform: none; color: var(--label); font-weight: 400; font-size: 14px; margin-bottom: 0px; display: block }
  
  
  .steps_cont { display: flex; justify-content: space-between; position: fixed; top: 74px; left: 0; right: 0; bottom: 0; overflow-y: auto; overflow-x: hidden; background: #fff; z-index: 100 }
  .steps_cont .steps_left { width: calc(100% - 320px);  padding-bottom: 36px; }
  .steps_cont .steps_right { width: 360px; background: #fb9550; position: relative; z-index: 1; min-height: calc((var(--vh, 1vh) * 100) - 69px); }
  .steps_cont .steps_right img { position: absolute; left: -180px; top: 114px; }
  .steps_cont:after { content: ''; position: fixed; width: 360px; background: #fb9550; top: 0; bottom: 0; right: 0; }
  
  
  .steps_format { margin-left: 176px; margin-right: 80px; padding-bottom: 48px; }
  .steps_format .step .anchor { position: relative; top: 180px; width: 0; }
  .steps_format .step .title { display: flex; align-items: center }
  .steps_format .step .title .nb { width: 24px; height: 24px; color: #fff; background: var(--orange); border-radius: 50%; display: flex; align-items: center; justify-content: center;
    font-size: 14px; padding-top: 2px; margin-right: 20px;
  }
  .steps_format .step .title .txt { font-size: 18px; font-weight: 500; }
  .steps_format .step .content { padding: 18px 0 48px 32px; border-left: 1px solid #dcdcdc; margin-left: 12px; margin-top: 8px; margin-bottom: 8px;
    /*max-width: 400px;*/ display: flex; flex-direction: column;
  }
  .steps_format .step.close .content { padding: 8px 0 8px 10px }
  .steps_format .step.close .content .i { display: none; }
  .steps_format .step.close .title { color: #bebebe; font-weight: 400; }
  .steps_format .step.close .title .nb { background: #acacac }
  .steps_format .step.close .title .txt { font-weight: 400; }
  .steps_format .step.close:last-child .content { display: none }
  
  .steps_title { margin-bottom: 64px; margin-top: 48px; margin-left: 120px; display: flex; align-items: center; font-weight: 600; }
  .steps_title .close { display: block; width: 40px; height: 40px; margin-right: 16px; display: flex; align-items: center; justify-content: center; fill: var(--black-sub); cursor: pointer; }
  
  
  .upload_block { width: 440px; padding-bottom: 440px; height: 0; position: relative; }
  
  .mobile_only { display: none }
  
  @media only screen and (max-width: 1400px) {
    .steps_title { margin-left: 20px; margin-top: 24px; }
    .steps_format { margin-left: 76px; }
    
  }
  @media only screen and (max-width: 1200px) {
    .steps_cont .steps_right { display: none }
    .steps_cont:after { display: none }
  }
  @media only screen and (max-width: 800px) {
    .steps_format { margin-left: 20px; margin-right: 28px; }
    .steps_title { margin-left: 12px; margin-bottom: 32px; }
    .steps_cont .steps_left { width: 100%; }
    .upload_block { width: 100%; padding-bottom: 100%; }
    .mobile_only { display: flex; }
  }

</style>